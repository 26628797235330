import {
	Grid,
	GridItem,
	Heading,
	Box,
	BoxProps,
	Flex,
	FlexProps,
	useColorModeValue,
	useToast,
	Spinner,
	useDisclosure,
	Icon,
	Drawer,
	VStack,
	DrawerContent,
	IconButton,
	CloseButton,
	Button,
	Tooltip,
	TableContainer,
	Table,
	Tr,
	Td,
	Tbody,
	FormControl,
	FormLabel,
	Input,
	Select,
	Checkbox,
	FormErrorMessage,
	SimpleGrid,
	Thead,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	HStack,
} from '@chakra-ui/react';
import {
	FiHome,
	FiTrendingUp,
	FiMonitor,
	FiUsers,
	FiLayers,
	FiSettings,
	FiAlertCircle,
	FiMenu,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { CheckCircleIcon, WarningIcon, DeleteIcon, UnlockIcon } from '@chakra-ui/icons';
import {
	useAddControllerAdminMutation,
	useAddTenantMutation,
	useAddUserAdminMutation,
	useGetControllerAdminQuery,
	useGetControllersAdminQuery,
	useGetTenantQuery,
	useGetTenantsQuery,
	useGetUserAdminQuery,
	useGetUsersAdminQuery,
	useUpdateTenantMutation,
	useUpdateUserAdminMutation
} from '../../features/api/api'
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { ChildrenProps, Controller, Tenant, User } from '../../types';
import { Formik, Field } from 'formik';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { UsersTable } from './modals';
import { ResetPasswordForm } from '../../features/user/user';
import { useAppDispatch } from '../../app/hooks';
import { login } from '../../features/user/userSlice';



const OnlineIcon = (online: boolean) => {
	return online ? <Tooltip label='Online' fontSize='md'><Icon as={CheckCircleIcon} color="green.400" /></Tooltip> :
		<Tooltip label='Offline' fontSize='md'><Icon as={WarningIcon} color="red.400" /></Tooltip>
}


interface MyFormValues {
	firstName: string;
}

const validateNotEmpty = (value: string) => {

	if (!value) {
		return "Kan inte vara tom";
	}
}

export const AdminControllerAddNewPage = () => {
	const [addController, addResult] = useAddControllerAdminMutation();
	const { data: tenants, error: tenantsError, isLoading: tenantsIsLoading } = useGetTenantsQuery();

	const navigate = useNavigate();
	const toast = useToast()


	const initialValues = {
		name: "",
		type: "",
		location: "",
		tenantId: "",
	} as Controller
	return (
		<AdminPage >
			<Box bg="white" p={6} rounded="md" >
				<Formik
					initialValues={initialValues}
					onSubmit={(values) => {
						addController(values).unwrap().then((controller) => {
							toast({
								title: `Skapad!`,
								status: "success",
								isClosable: true,
							})
							navigate('/inside/admin/controllers/' + controller.id)
						});
						//TODO navigate to newly created or to list?
					}}
				>
					{({ handleSubmit, errors, touched }) => (
						<form onSubmit={handleSubmit}>

							<FormControl isInvalid={!!errors.name && touched.name}>
								<FormLabel >Namn</FormLabel>
								<Field
									as={Input}
									id="name"
									name="name"
									validate={validateNotEmpty}
								/>
								<FormErrorMessage>{errors.name}</FormErrorMessage>
							</FormControl>

							<FormControl isInvalid={!!errors.location && touched.location}>
								<FormLabel >Plats</FormLabel>
								<Field
									as={Input}
									id="location"
									name="location"
									validate={validateNotEmpty}
								/>
								<FormErrorMessage>{errors.location}</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!errors.type && touched.type}>
								<FormLabel >Typ</FormLabel>
								<Field
									as={Input}
									id="type"
									name="type"
									validate={validateNotEmpty}
								/>
								<FormErrorMessage>{errors.type}</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!errors.tenantId && touched.tenantId}>
								<FormLabel >Tenant</FormLabel>
								<Field
									as={Select}
									id="tenantId"
									name="tenantId"
									validate={validateNotEmpty}
								>
									<option value="">Välj tenant</option>
									{tenants?.map(({ id, name }) => (
										<option key={id} value={id}>
											{name}
										</option>
									))}
								</Field>
								<FormErrorMessage>{errors.tenantId}</FormErrorMessage>
							</FormControl>

							<Button mt={6} type="submit" colorScheme="teal" width="200px">
								Skapa
							</Button>
						</form>
					)}
				</Formik>
			</Box>
		</AdminPage>
	);

}


export const AdminControllerPage = () => {
	const { id } = useParams() as { id: string };
	const { data, error, isLoading } = useGetControllerAdminQuery(id);


	const [addTenant, addResult] = useAddTenantMutation();
	// const [updateTenant, updateResult] = useUpdateTenantMutation();

	const navigate = useNavigate();
	const toast = useToast()
	if (isLoading) {
		return <Spinner size='xl' />;
	}

	if (error) {
		console.log("error is", error);
		return null;
	}



	const generateToken = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		const response = await fetch('/api/token-v1', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ controllerId: data?.id ?? '', tenantId: data?.tenantId ?? '' })
		});
		if (response.ok) {
			let json = await response.json();
			toast({
				title: `Nytt token: ` + json.token,
				status: "success",
				isClosable: true,
			})
			return;
		}
		throw new Error("failed");

	}

	return (
		<AdminPage >
			<div>
				<div>
					<Heading as='h1' size='xl' >
						{data?.name} {OnlineIcon(data?.online ?? false)}
					</Heading>
					{data?.location ?? ''}
				</div>
				<div>
					<Button
						mt={10}
						bg={'teal.400'}
						color={'white'}
						rounded={'lg'}
						onClick={generateToken}
						_hover={{
							bg: 'teal.500',
						}}
						_focus={{
							bg: 'teal.500',
						}}>
						Generera token
					</Button>
				</div>
			</div>
		</AdminPage>
	)
}

export const AdminControllersPage = () => {
	// const { id } = useParams() as { id: string };
	const { data, error, isLoading } = useGetControllersAdminQuery();
	const navigate = useNavigate();
	const clickRow = (id: string) => {
		navigate('/inside/admin/controllers/' + id)
	};
	if (isLoading || !data) {
		return <Spinner size='xl' />;
	}

	if (error) {
		console.log("error is", error);
		return null;
	}
	return (
		<AdminPage >
			<TableContainer>
				<Button
					// mt={10}
					float={'right'}
					bg={'teal.400'}
					color={'white'}
					rounded={'lg'}
					as={RouterLink}
					to={'/inside/admin/controllers/new'}
					_hover={{
						bg: 'teal.500',
					}}
					_focus={{
						bg: 'teal.500',
					}}>
					Skapa ny
				</Button>
				<Table size='sm'>
					<Tbody>
						{data?.map((row) => (
							<Tr _hover={{
								bg: 'teal.400',
								color: 'white',
								cursor: 'pointer',
							}}
								onClick={() => clickRow(row.id)}
								key={row.id}
							>
								<Td>{row.tenant?.name}</Td>
								<Td>{row.name} {OnlineIcon(row.online)}</Td>
								<Td>{row.location}</Td>
								<Td>{row.type}</Td>
								<Td>{row.lastUpdate}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</AdminPage>
	)
}

export const AdminTenantsPage = () => {
	const { data, error, isLoading } = useGetTenantsQuery();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const toast = useToast()
	const clickRow = (id: string | undefined) => {
		if (!id) {
			return;
		}
		navigate('/inside/admin/tenants/' + id)
	};
	if (isLoading || !data) {
		return <Spinner size='xl' />;
	}

	if (error) {
		console.log("error is", error);
		return null;
	}

	const handleLogin = async (tenantID: string | undefined) => {
		if(!tenantID){
			return;
		}
		// CALL /api/tenants/support-login-v1
		const response = await fetch(`/api/tenants-v1/${tenantID}/support-login-v1`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			// body: JSON.stringify({ username: username, password: password })
		});
		if (!response.ok) {
			toast({
				title: `Inloggning misslyckades.`,
				status: "error",
				isClosable: true,
			})
			// throw new Error("login failed");
			return
		}
		// let json = await response.json();
		// dispatch(login(json));
		navigate('/inside/home');
		window.location.reload();
	}

	return (
		<AdminPage >
			<TableContainer>
				<Button
					// mt={10}
					float={'right'}
					bg={'teal.400'}
					color={'white'}
					rounded={'lg'}
					as={RouterLink}
					to={'/inside/admin/tenants/new'}
					_hover={{
						bg: 'teal.500',
					}}
					_focus={{
						bg: 'teal.500',
					}}>
					Skapa ny
				</Button>
				<Table size='sm'>
					<Tbody>
						{data?.map((row) => (
							<Tr _hover={{
								bg: 'teal.400',
								color: 'white',
								cursor: 'pointer',
							}}
								onClick={() => clickRow(row.id)}
								key={row.id}
							>
								<Td width={'200px'}>{row.id}</Td>
								<Td>{row.name} </Td>
								<Td width={'50px'}><IconButton
									variant="outline"
									onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
										e.stopPropagation();
										handleLogin(row.id);
									}}
									aria-label="supportlogin"
									icon={<UnlockIcon />}
								/>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</AdminPage>
	)
}

export const AdminTenantPage = () => {
	const { id } = useParams() as { id: string };
	const isNew = id === 'new';
	const tenant = useGetTenantQuery(isNew ? skipToken : id);

	const [addTenant, addResult] = useAddTenantMutation();
	const [updateTenant, updateResult] = useUpdateTenantMutation();
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure()
	if (tenant.isLoading) {
		return <Spinner size='xl' />;
	}

	const initialValues = tenant.data ?? {
		name: "",
		invoiceDetails: {
			address: "",
			city: "",
			postCode: "",
			name: ""
		},
		users: [],
	} as Tenant

	if (tenant.error) {
		console.log("error is", tenant.error);
		return null;
	}

	return (
		<AdminPage >
			<Box bg="white" p={6} rounded="md" >
				<Formik
					initialValues={initialValues}
					onSubmit={(values) => {
						if (isNew) {
							addTenant(values);
							navigate('/inside/admin/tenants');
							return;
						}
						updateTenant(values);
						navigate('/inside/admin/tenants');
					}}
				>
					{({ values, handleSubmit, setFieldValue/*, errors, touched*/ }) => (
						<form onSubmit={handleSubmit}>
							<VStack spacing={4} align="flex-start">

								<SimpleGrid columns={2} columnGap={3} rowGap={6} w="full">
									<GridItem colSpan={1}>
										<FormControl>
											<FormLabel >Namn</FormLabel>
											<Field
												as={Input}
												id="name"
												name="name"
											/>
										</FormControl>
										<TableContainer pt={6}>
											<Button
												// mt={10}
												onClick={onOpen}
												float={'right'}
												bg={'teal.400'}
												color={'white'}
												rounded={'lg'}
												_hover={{
													bg: 'teal.500',
												}}
												_focus={{
													bg: 'teal.500',
												}}>
												Lägg till
											</Button>
											<Modal isOpen={isOpen} onClose={onClose}>
												<ModalOverlay />
												<ModalContent minWidth="fit-content" height="fit-content">
													<ModalHeader>Lägg till användare</ModalHeader>
													<ModalCloseButton />
													<ModalBody>
														<UsersTable onClick={(user: User) => {
															const users = [...values.users ?? []];
															users.push(user);
															setFieldValue("users", users);
															onClose();
														}} />
													</ModalBody>

													<ModalFooter>
													</ModalFooter>
												</ModalContent>
											</Modal>
											<Heading>
												Användare
											</Heading>
											<Table size='sm'>
												<Thead>
													<Tr>
														<Td>Namn</Td>
														<Td>Användarnamn</Td>
														<Td>epost</Td>
														<Td></Td>
													</Tr>
												</Thead>
												<Tbody>
													{values.users?.map((row) => (
														<Tr
															key={row.id}
														>
															<Td>{row.name} </Td>
															<Td>{row.username} </Td>
															<Td>{row.email} </Td>
															<Td>
																<IconButton
																	variant="outline"
																	onClick={() => {
																		setFieldValue("users", [...values.users].filter((val) => val.id != row.id));
																	}}
																	aria-label="open menu"
																	icon={<DeleteIcon />}
																/>
															</Td>
														</Tr>
													))}
												</Tbody>
											</Table>
										</TableContainer>
									</GridItem>
									<GridItem colSpan={1}>
										<FormControl>
											<FormLabel >Namn</FormLabel>
											<Field
												as={Input}
												id="invoiceDetails.name"
												name="invoiceDetails.name"
											/>
										</FormControl>
										<FormControl>
											<FormLabel >Address</FormLabel>
											<Field
												as={Input}
												id="invoiceDetails.address"
												name="invoiceDetails.address"
											/>
										</FormControl>
										<FormControl>
											<FormLabel >Postnummer</FormLabel>
											<Field
												as={Input}
												id="name"
												name="invoiceDetails.postCode"
											/>
										</FormControl>
										<FormControl>
											<FormLabel >Stad</FormLabel>
											<Field
												as={Input}
												id="invoiceDetails.city"
												name="invoiceDetails.city"
											/>
										</FormControl>
										<FormControl>
											<FormLabel >Faktureringsintervall (månad)</FormLabel>
											<Field
												as={Input}
												type='number'
												id="invoiceDetails.interval"
												name="invoiceDetails.interval"
											/>
										</FormControl>

									</GridItem>


								</SimpleGrid>
							</VStack>
							<Button type="submit" colorScheme="teal" width="200px">
								{isNew ? 'Skapa' : 'Spara'}
							</Button>
						</form>
					)}
				</Formik>
			</Box>
		</AdminPage>
	)
}

export const AdminStatusPage = () => {
	return (
		<AdminPage >
			<TableContainer>
				<Table w='400px' size='sm'>
					<Tbody>
						<Tr>
							<Td>Framledning</Td>
							<Td isNumeric>25.4&deg;</Td>
						</Tr>
						<Tr>
							<Td>Varmvatten</Td>
							<Td isNumeric>46.48&deg;</Td>
						</Tr>
						<Tr>
							<Td>brine in</Td>
							<Td isNumeric>4.5&deg;</Td>
						</Tr>
						<Tr>
							<Td>brine ut</Td>
							<Td isNumeric>2.5&deg;</Td>
						</Tr>
					</Tbody>
				</Table>
			</TableContainer>

		</AdminPage>
	)
}



export const AdminPage = ({ children }: ChildrenProps) => {


	// const online = data.online ? <Tooltip label='Online' fontSize='md'><Icon as={CheckCircleIcon} color="green.400" /></Tooltip> :
	// 	<Tooltip label='Offline' fontSize='md'><Icon as={WarningIcon} color="red.400" /></Tooltip>
	return (
		<Grid
			templateAreas={`"nav main main"`}
			gridTemplateColumns={'180px 1fr'}
			//h='200px'
			gap='1'
			color='blackAlpha.700'
		>

			<GridItem
				// bg='pink.300'
				pt='15px'
				pb='15px'
				area={'nav'}
				fontWeight='bold'
			>
				<SimpleSidebar linkItems={LinkItems}></SimpleSidebar>
			</GridItem>
			<GridItem p='15px' area={'main'}>
				{children}
			</GridItem>
		</Grid>
	);
}

interface LinkItemProp {
	name: string;
	to: string;
	icon: IconType;
}

interface LinkItemProps {
	linkItems?: Array<LinkItemProp>;
}
const LinkItems: Array<LinkItemProp> = [
	{ name: 'Status', icon: FiHome, to: 'status' },
	{ name: 'Controllers', icon: FiMonitor, to: 'controllers' },
	{ name: 'Tenants', icon: FiLayers, to: 'tenants' },
	{ name: 'Users', icon: FiUsers, to: 'users' },
	// { name: 'Explore', icon: FiCompass },
	// { name: 'Favourites', icon: FiStar },
	// { name: 'Larm', icon: FiAlertCircle, to: 'alarms' },
	// { name: 'Inställningar', icon: FiSettings, to: 'settings' },
];

export function SimpleSidebar({ linkItems, ...rest }: LinkItemProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Box bg={useColorModeValue('gray.100', 'gray.900')}>
			<SidebarContent
				linkItems={linkItems}
				onClose={() => onClose}
				display={{ base: 'none', md: 'block' }}
				{...rest}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="sm">
				<DrawerContent>
					<SidebarContent linkItems={linkItems} onClose={onClose} />
				</DrawerContent>
			</Drawer>
			{/* mobilenav */}
			<MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
		</Box>
	);
}

interface SidebarProps extends BoxProps {
	onClose: () => void;
	linkItems?: Array<LinkItemProp>;
}

const SidebarContent = ({ linkItems, onClose, ...rest }: SidebarProps) => {
	return (
		<Box
			pl="10px"
			pr="10px"
			bg={useColorModeValue('white', 'gray.900')}
			{...rest}>
			<CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
			{linkItems?.map((link) => (
				<NavItem as={RouterLink} key={link.name} icon={link.icon} to={'/inside/admin/' + link.to}>
					{link.name}
				</NavItem>
			))}
		</Box>
	);
};

interface NavItemProps extends FlexProps {
	icon: IconType;
	to: string;
	children: string;
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
	return (
		<Flex
			align="center"
			p="10px"
			// mx="4"
			borderRadius="lg"
			role="group"
			cursor="pointer"
			_hover={{
				bg: 'teal.400',
				color: 'white',
			}}
			{...rest}>
			{icon && (
				<Icon
					mr="4"
					fontSize="16"
					_groupHover={{
						color: 'white',
					}}
					as={icon}
				/>
			)}
			{children}
		</Flex>
	);
};

interface MobileProps extends FlexProps {
	onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 24 }}
			height="20"
			alignItems="center"
			bg={useColorModeValue('white', 'gray.900')}
			borderBottomWidth="1px"
			borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
			justifyContent="flex-start"
			{...rest}>
			<IconButton
				variant="outline"
				onClick={onOpen}
				aria-label="open menu"
				icon={<FiMenu />}
			/>
		</Flex>
	);
}

export const AdminUsersPage = () => {
	const { data, error, isLoading } = useGetUsersAdminQuery();
	const navigate = useNavigate();
	const clickRow = (id: string) => {
		if (!id) {
			return;
		}
		navigate('/inside/admin/users/' + id)
	};
	if (isLoading || !data) {
		return <Spinner size='xl' />;
	}

	if (error) {
		console.log("error is", error);
		return null;
	}
	return (
		<AdminPage >
			<TableContainer>
				<Button
					// mt={10}
					float={'right'}
					bg={'teal.400'}
					color={'white'}
					rounded={'lg'}
					as={RouterLink}
					to={'/inside/admin/users/new'}
					_hover={{
						bg: 'teal.500',
					}}
					_focus={{
						bg: 'teal.500',
					}}>
					Skapa ny
				</Button>
				<Table size='sm'>
					<Thead>
						<Tr>
							<Td>ID</Td>
							<Td>Name</Td>
							<Td>username</Td>
							<Td>email</Td>
						</Tr>
					</Thead>
					<Tbody>
						{data?.map((row) => (
							<Tr _hover={{
								bg: 'teal.400',
								color: 'white',
								cursor: 'pointer',
							}}
								onClick={() => clickRow(row.id)}
								key={row.id}
							>
								<Td width={'200px'}>{row.id}</Td>
								<Td>{row.name} </Td>
								<Td>{row.username} </Td>
								<Td>{row.email} </Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</AdminPage>
	)
}

export const AdminUserPage = () => {
	const { id } = useParams() as { id: string };
	const isNew = id === 'new';
	const { data, error, isLoading } = useGetUserAdminQuery(isNew ? skipToken : id);

	const [addUser, addResult] = useAddUserAdminMutation();
	const [updateUser, updateResult] = useUpdateUserAdminMutation();
	const toast = useToast()
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure()
	// console.log(result);
	if (isLoading) {
		return <Spinner size='xl' />;
	}

	const initialValues = data ?? {
		name: "",
		username: "",
		email: "",
		personalIdentityNumber: "",
	} as User

	const emailPassword = async () => {
		const response = await fetch(`/api/admin/users-v1/${id}/send-password-v1`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
		});
		if (!response.ok) {
			toast({
				title: `Skicka lösenord misslyckades.`,
				status: "error",
				isClosable: true,
			})
			return;
		}
		toast({
			title: `Länk skickad för att byta lösenord.`,
			status: "success",
			isClosable: true,
		})
	}

	if (error) {
		console.log("error is", error);
		return null;
	}
	return (
		<AdminPage >
			<Box bg="white" p={6} rounded="md" >

				<HStack spacing={5} justifyContent='right'>
					<Button
						// mt={10}
						onClick={onOpen}
						bg={'teal.400'}
						color={'white'}
					>
						Byt lösenord
					</Button>

					<Modal isOpen={isOpen} onClose={onClose}>
						<ModalOverlay />
						<ModalContent minWidth="fit-content" height="fit-content">
							<ModalHeader>Byt lösenord</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								<ResetPasswordForm userId={id} onSubmit={() => {
									onClose();
								}} />
							</ModalBody>

							<ModalFooter>
							</ModalFooter>
						</ModalContent>
					</Modal>
					<Button
						onClick={emailPassword} //TODO
						bg={'teal.400'}
						color={'white'}
					>
						Skicka lösenord
					</Button>

				</HStack>
				<Formik
					initialValues={initialValues}
					onSubmit={(values) => {
						if (isNew) {
							addUser(values);
							navigate('/inside/admin/users');
							return;
						}
						updateUser(values);
						navigate('/inside/admin/users');
					}}
				>
					{({ handleSubmit, errors, touched }) => (
						<form onSubmit={handleSubmit}>
							<VStack spacing={4} align="flex-start">

								<SimpleGrid columns={2} columnGap={3} rowGap={6} w="full">
									<GridItem colSpan={1}>
										<FormControl isInvalid={!!errors.name && touched.name}>
											<FormLabel >Namn</FormLabel>
											<Field
												as={Input}
												id="name"
												name="name"
												validate={validateNotEmpty}
											/>
											<FormErrorMessage>{errors.name}</FormErrorMessage>
										</FormControl>
										<FormControl isInvalid={!!errors.username && touched.username}>
											<FormLabel >Användarnamn</FormLabel>
											<Field
												as={Input}
												id="username"
												name="username"
												validate={validateNotEmpty}
											/>
											<FormErrorMessage>{errors.username}</FormErrorMessage>
										</FormControl>
									</GridItem>
									<GridItem colSpan={1}>
										<FormControl isInvalid={!!errors.email && touched.email}>
											<FormLabel >Epost</FormLabel>
											<Field
												as={Input}
												id="email"
												name="email"
												validate={validateNotEmpty}
											/>
											<FormErrorMessage>{errors.email}</FormErrorMessage>
										</FormControl>
										<FormControl isInvalid={!!errors.personalIdentityNumber && touched.personalIdentityNumber}>
											<FormLabel >Personnummer</FormLabel>
											<Field
												as={Input}
												id="personalIdentityNumber"
												name="personalIdentityNumber"
											/>
											<FormErrorMessage>{errors.personalIdentityNumber}</FormErrorMessage>
										</FormControl>
									</GridItem>


								</SimpleGrid>
							</VStack>
							<Button type="submit" colorScheme="teal" width="200px">
								{isNew ? 'Skapa' : 'Spara'}
							</Button>
						</form>
					)}
				</Formik>
			</Box>
		</AdminPage>
	)
}
